<template>
  <div class="course-details">
    <div class="course-details-box">
      <div class="pc-goBack-template">
        <div class="pc-goBack-box">
          <div class="pc-goBack" @click="redirectPage">
            <i class="el-icon-arrow-left"></i>
            <div class="pc-goBack-title">返回课程组详情</div>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="coverInfo-box-template">
          <div class="coverInfo-box">
            <div class="cover">
              <img :src="courseDetails.picture" alt="" v-if="pictureImgExists" />
              <img src="../../assets/placeholder.png" alt="" v-else />
            </div>
            <div class="info-content">
              <div class="title">{{ courseDetails.courseName }}</div>
              <div class="pc-price-box">
                <!-- <div class="pc-price">¥{{ courseDetails.currentPrice }}</div> -->
                <div class="author-time">
                  <div class="author-time-box">
                    <span>作者：{{ courseDetails.lecturerName }}</span>
                    <span>共{{ courseDetails.lessonCount }}节课</span>
                    <span v-if="seconds">时长{{ lessonDurationAll }}</span>
                  </div>
                  <!-- <div class="pc-day">
                    <img src="../../assets/time-act.png" alt="" />
                    <span>365天随时看</span>
                  </div> -->
                </div>
              </div>
              <div class="buy-box">
                <div class="buy-way">
                  <!-- <p>购买方式</p>
                  <div>企业购买</div> -->
                </div>
                <div class="buy-num">
                  <!-- <p>数量</p> -->
                  <div>
                    <!-- {{ pcBuyNum }} -->
                    <!-- <input-number :min="1" :max="999" :value="pcBuyNum" @changeNum="changeNum"></input-number> -->
                  </div>
                </div>
              </div>
              <div class="price-day">
                <!-- <div class="price">¥{{ courseDetails.currentPrice }}</div>
                <div class="day">
                  <img src="../../assets/time-act.png" alt="" />
                  <span>365天随时看</span>
                </div> -->
              </div>
              <div class="pc-details-btn">
                <!-- <div class="pc-btn-buy" @click="goConfirmOrder">立即购买</div>
                <div class="pc-btn-study" @click="redirectWebDowload">下载App学习</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="tab" :style="{ '--positionStyle': positionStyle, '--paddingTopStyle': paddingTopStyle, '--afterDisplay': afterDisplay }">
          <!-- <TagBox :tagList='tagList' @getTagId="getTagId" :activeIndex="activeIndex" :padRgt=""></TagBox> -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane class="b" label="课程简介" name="1">
              <div v-html="courseDetails.courseIntroduce" class="ql-editor"></div>
              <!-- <img :src="courseDetails.courseIntroduce" alt="" /> -->
            </el-tab-pane>
            <el-tab-pane label="目录" name="2">
              <catalog-box :chapterList="chapterList" v-if="chapterList.length > 0"></catalog-box>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="details-btn" v-if="visibleBuyButton">
          <div class="btn-buy" @click="goConfirmOrder" v-if="visibleBuyButton">立即购买</div>
          <!-- <div class="btn-study" @click="goApp">下载App学习</div> -->
        </div>
      </div>
      <NoData v-if="noDataShow && activeName == '2'">
        <template slot="text">暂无数据</template>
      </NoData>
      <alert-msg :time="1500" :visibleMsg.sync="isShowErrMsg">{{ errMsgText }}</alert-msg>
      <AffirmDialog :affirmDialogShow="affirmDialogShow" @submitDialog="submitDialog" @cancelDialog="cancelDialog">{{ contentText }}</AffirmDialog>
    </div>
  </div>
</template>
<script>
import { getPublishBaseUrl, getWebDownloadUrl } from '@/utils/config.js'
import { getCourseDetails } from '@/api/courseDetails'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import { checkImgExists } from '@/utils/common'
import CatalogBox from './components/catalogBox.vue'
import Footer from '@/layout/Footer'
export default {
  name: 'CourseDetails',
  components: {
    CatalogBox,
    Footer
  },
  data() {
    return {
      activeName: '1',
      id: null,
      activeIndex: 0,
      tagList: [
        {
          label: '课程简介',
          id: '1',
          index: 0
        },
        {
          label: '目录',
          id: '2',
          index: 1
        }
      ],
      courseDetails: {},
      chapterList: [], // 目录
      lecturer: {}, // 讲师信息
      seconds: 0, // 秒数
      lessonDurationAll: '0分钟', // 总时长
      affirmDialogShow: false,
      contentText: '您当前还未登录，请登录后再进行购买。',
      positionStyle: 'static', // static fixed
      paddingTopStyle: '0', // '0' '54px'
      afterDisplay: 'none', // 'none'  'block'
      errMsgText: null,
      isShowErrMsg: false,
      noDataShow: false,
      page: '',
      visibleBuyButton: true,
      pcBuyNum: 1,
      inputMin: 1,
      inputMax: 999,
      pictureImgExists: true,
      backId: null
    }
  },
  computed: {
    ...mapGetters(['equipmentType'])
  },
  // watch: {
  //   equipmentType: {
  //     handler: function (newVal, oldVal) {
  //       if (newVal == 'APP') {
  //         window.addEventListener('scroll', this.handleScroll)
  //       } else {
  //         window.removeEventListener('scroll', this.handleScroll)
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  created() {
    this.id = this.$route.query.id
    this.backId = this.$route.query.backId
    this.page = this.$route.query.page
    this.getPage(this.page)
    this.getCourseDetails(this.$route.query.id)
    // window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getCourseDetails(id) {
      getCourseDetails(id)
        .then(async res => {
          this.courseDetails = res.data
          this.courseDetails.courseIntroduce = this.showHtml(this.courseDetails.courseIntroduce)
          this.chapterList = res.data?.chapterList || []
          this.noDataShow = this.chapterList.length === 0 ? true : false
          this.lecturer = res.data?.lecturer || {}
          this.chapterList.forEach(chapterItem => {
            this.$set(chapterItem, 'isSubShow', false)
            chapterItem?.lessonList?.forEach(lessonItem => {
              this.seconds += lessonItem.lessonDuration
            })
          })
          this.pictureImgExists = await checkImgExists(this.courseDetails.picture)
          this.convertTime(this.seconds)
        })
        .catch(err => {
          this.errMsgText = err.msg
          this.isShowErrMsg = true
          this.noDataShow = true
          setTimeout(() => {
            this.isShowErrMsg = false
          }, 1500)
        })
    },
    // 秒数转换
    convertTime(num) {
      // console.log("🚀 ~ num", num)
      let hour = 0
      let min = 0
      let sec = 0
      if (num >= 3600) {
        hour = Math.floor(Math.floor(Math.round(num) / 60) / 60)
        min = Math.floor(Math.round(num) / 60) % 60
        sec = Math.round(num) % 60
        this.lessonDurationAll = `${hour}小时${min}分钟${sec}秒`
        return this.lessonDurationAll
      } else if (num >= 60 && num < 3600) {
        min = Math.floor(Math.round(num) / 60) % 60
        sec = Math.round(num) % 60
        this.lessonDurationAll = `${min}分钟${sec}秒`
        return this.lessonDurationAll
      } else {
        sec = Math.round(num) % 60
        this.lessonDurationAll = `${sec}秒`
        return this.lessonDurationAll
      }
    },
    // 页面滚动
    // handleScroll() {
    //   let scroll = document.documentElement.scrollTop || document.body.scrollTop
    //   if (scroll >= 125 && this.equipmentType == 'APP') {
    //     this.positionStyle = 'fixed'
    //     this.paddingTopStyle = '54px'
    //     this.afterDisplay = 'block'
    //   } else {
    //     this.positionStyle = 'static'
    //     this.paddingTopStyle = '0'
    //     this.afterDisplay = 'none'
    //   }
    // },
    changeNum(value) {
      if (isNaN(value)) {
        this.pcBuyNum = null
      } else {
        this.pcBuyNum = value
      }
    },
    handleClick(tab, event) {},
    // 弹框确认按钮
    submitDialog() {
      this.affirmDialogShow = false
      if (this.equipmentType == 'APP') {
        this.$router
          .replace({
            path: '/confirmOrder',
            query: { id: this.id }
          })
          .catch(err => {})
      } else {
        this.$router
          .replace({
            path: '/confirmOrder',
            query: { id: this.id, numberValue: this.pcBuyNum }
          })
          .catch(err => {})
      }
    },
    // 弹框取消按钮
    cancelDialog() {
      this.affirmDialogShow = false
    },
    // 立即购买按钮
    goConfirmOrder() {
      if (getToken() != undefined) {
        this.$router
          .push({
            path: '/confirmOrder',
            query: {
              id: this.id,
              numberValue: this.pcBuyNum
            }
          })
          .catch(err => {
            console.log('err', err)
          })
      } else {
        this.affirmDialogShow = true
      }
    },
    getPage(value) {
      switch (value) {
        case 'courseCompany':
          // 个人中心--企业登录-我的课程跳转进入
          this.visibleBuyButton = false
          break
        case 'courseApp':
          // 个人中心--个人登录-我的课程跳转进入
          this.visibleBuyButton = false
          break
        default:
          this.visibleBuyButton = true
      }
    },
    goApp() {
      window.location.href = getWebDownloadUrl() + '/download?type=screen'
    },
    redirectWebDowload() {
      window.location.href = getWebDownloadUrl() + '/download'
    },
    redirectPage() {
      window.location.href = getPublishBaseUrl() + `/courseGroupDetail?id=${this.backId}`
    },
    showHtml(str) {
      return str.replace(/<img/g, '<img style="max-width:100%;height:auto;display:inline-block;margin:10px 0;"')
    }
  }
}
</script>
<style lang="scss" scoped>
// @media (max-width: 767px) {
.course-details {
  padding: 44px 0 0;
  background: #f9f9f9;
  .course-details-box {
    position: relative;
    .pc-goBack-box {
      display: none;
    }
    .el-icon-arrow-left {
      cursor: pointer;
    }
    .info {
      .coverInfo-box {
        .cover {
          height: 212px;
          position: fixed;
          width: 100%;
          z-index: 9;
          img {
            width: 100%;
            height: 100%;
            border-radius: 7px;
          }
        }
        .info-content {
          // padding: 12px 21px 23px;
          padding: 224px 21px 23px;
          .title {
            font-weight: 500;
            color: #363c42;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 25px;
            max-height: 25px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .pc-price {
            display: none;
          }
          .author-time {
            .author-time-box {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              color: #8e8e93;
              padding: 10px 0;
            }
            .pc-day {
              display: none;
            }
          }
          .buy-box {
            display: none;
          }
          .price-day {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .pc-details-btn {
            display: none;
          }
          .price {
            font-size: 18px;
            font-weight: bold;
            color: #d30000;
          }
          .day {
            display: flex;
            img {
              width: 15px;
              height: 15px;
              margin-right: 7px;
            }
            span {
              color: $robin-title-color;
              font-size: 12px;
            }
          }
        }
      }
      .tab ::v-deep {
        // padding-bottom: 87px;
        img {
          // padding-top: var(--paddingTopStyle);
          padding-top: 0px;
          padding-bottom: 87px;
        }
        .el-tabs__nav-scroll {
          display: flex;
          justify-content: center;
        }
        .el-tabs__header {
          padding: 0 21px;
          background: #fff;
          // position: var(--positionStyle);
          position: sticky;
          top: 255px;
          width: 100%;
          z-index: 9;
          &::after {
            // display: var(--afterDisplay);
            display: block;
            position: absolute;
            content: '';
            top: 39px;
            left: 0;
            width: 100%;
            height: 15px;
            background: #f9f9f9;
          }
        }
        .el-tabs__content {
          background: #fff;
        }
        .el-tabs__nav-wrap::after {
          display: none;
        }
        .el-tabs__item {
          font-size: 16px;
          color: $robin-title-color;
        }
        .el-tabs__item.is-active {
          color: $robin-essential-color;
        }
        .el-tabs__active-bar {
          background-color: $robin-essential-color;
        }
      }
      .details-btn {
        position: fixed;
        background: #fff;
        padding: 12px 35px 30px;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        div {
          width: 100%;
          text-align: center;
          height: 45px;
          line-height: 45px;
          color: #fff;
          font-size: 14px;
          border-radius: 23px;
        }
        .btn-buy {
          background: $robin-essential-color;
        }
        .btn-study {
          margin-left: 13px;
          background: #3b82f6;
        }
        div:first-child {
          margin: 0;
        }
      }
    }
  }
}
// }
@media (min-width: 768px) {
  .course-details {
    padding: 80px 1rem 0;
    min-height: 100vh;
    .course-details-box {
      .pc-goBack-template {
        background: #fff;
      }
      .pc-goBack-box {
        margin: 0 auto;
        max-width: 1200px;
        display: block;
        .pc-goBack {
          padding-top: 31px;
          padding-bottom: 43px;
          display: flex;
          align-items: center;
          .pc-goBack-title {
            font-size: 22px;
            font-weight: bold;
            color: $robin-title-color;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
      .info {
        .coverInfo-box-template {
          background: #fff;
          padding-bottom: 34px;
        }
        .coverInfo-box {
          margin: 0 auto;
          max-width: 1200px;
          width: 100%;
          display: flex;
          .cover {
            width: 389px;
            height: 248px;
            margin-right: 30px;
            border-radius: 7px;
            position: static;
          }
          .info-content {
            width: calc(100% - 419px);
            padding: 5px 0 0;
            height: 248px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
              font-size: 18px;
              font-weight: bold;
              // margin-bottom: 20px;
            }
            .pc-price-box {
              background: #f8f8f8;
              padding: 11px 0 16px 0;
            }
            .pc-price {
              display: block;
              font-size: 18px;
              font-weight: bold;
              color: #d30000;
              padding-left: 19px;
              margin-bottom: 4px;
              background: #f8f8f8;
            }
            .author-time {
              background: #f8f8f8;
              display: flex;
              padding-left: 19px;
              max-width: 480px;
              .author-time-box {
                padding: 0;
                width: calc(100% - 140px);
              }
              .pc-day {
                width: 140px;
                margin-left: 37px;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                img {
                  width: 15px;
                  height: 15px;
                  margin-right: 6px;
                }
                span {
                  color: $robin-title-color;
                  font-size: 12px;
                }
              }
            }
            .buy-box {
              display: flex;
              // margin-top: 27px;
              // margin-bottom: 49px;
              p {
                margin-right: 18px;
                font-weight: 400;
                font-size: 12px;
              }
              .buy-way {
                display: flex;
                margin-right: 30px;
                align-items: center;
                div {
                  width: 96px;
                  height: 24px;
                  line-height: 24px;
                  text-align: center;
                  border-radius: 2px;
                  font-size: 12px;
                  font-weight: 400;
                  color: $robin-essential-color;
                  border: 1px solid $robin-essential-color;
                }
              }
              .buy-num {
                display: flex;
                align-items: center;
                div {
                  width: 96px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  border-radius: 2px;
                  // border: 1px solid #8e8e93;
                  color: #979797;
                  span {
                    display: block;
                    text-align: center;
                  }
                  .buy-num-btn {
                    width: 24px;
                    cursor: pointer;
                  }
                  .num-show {
                    width: calc(100% - 48px);
                    height: 22px;
                    text-align: center;
                    outline: none;
                    border-right: 1px solid #979797;
                    border-left: 1px solid #979797;
                  }
                }
              }
            }
            .price-day {
              display: none;
            }
            .pc-details-btn {
              display: flex;
              div {
                cursor: pointer;
                width: 162px;
                height: 46px;
                font-size: 14px;
                line-height: 46px;
                text-align: center;
                font-weight: 400;
                color: #ffffff;
                border-radius: 23px;
              }
              .pc-btn-buy {
                background: $robin-essential-color;
              }
              .pc-btn-study {
                background: #3b82f6;
                margin-left: 30px;
              }
            }
          }
        }
        .tab ::v-deep {
          margin: 58px auto 0;
          max-width: 1200px;
          .el-tabs__header {
            border-bottom: 1px solid #dadada;
            position: static;
            &::after {
              display: none;
            }
          }
          // img {
          //   width: 100%;
          // }
        }
        .details-btn {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 1201px) {
  .course-details {
    padding: 80px 0 0;
  }
}
</style>
