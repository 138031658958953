<template>
  <div class="catalog-box">
    <div class="catalog" v-for="(itemChapter, index1) in chapterList" :key="index1">
      <div class="catalog-title" @click="changeChapter(itemChapter, index1)">
        <p>{{ itemChapter.chapterName }}</p>
        <i class="el-icon-arrow-down" :class="itemChapter.isSubShow ? 'i-active' : ''"></i>
      </div>
      <!-- 折叠小课程 -->
      <div class="lesson-box" v-show="itemChapter.isSubShow">
        <div v-if="itemChapter.lessonList && itemChapter.lessonList.length > 0">
          <div class="lesson" v-for="(itemLesson, index2) in itemChapter.lessonList" :key="index2">
            <div class="lesson-title">{{ itemLesson.lessonName }}</div>
            <div class="lesson-time" v-if="itemLesson.lessonDuration">{{ secondsFormat(itemLesson.lessonDuration) }}</div>
          </div>
        </div>
        <div v-else class="no-lesson">本章节暂时还没有课程噢~</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'catalogBox',
  props: {
    chapterList: {
      type: Array,
      default: () => [
        {
          chapterName: '开篇：持续成功的关键1',
          lessonList: [
            {
              lessonName: '课程预告1'
            },
            {
              lessonName: '课程预告2'
            },
            {
              lessonName: '课程预告3'
            }
          ],
          id: 1
        },
        {
          chapterName: '开篇：持续成功的关键2',
          lessonList: [
            {
              lessonName: '课程预告1'
            },
            {
              lessonName: '课程预告2'
            },
            {
              lessonName: '课程预告3'
            }
          ],
          id: 2
        }
      ]
    }
  },
  data() {
    return {
      itemChapterId: null,
      lessonBoxShow: false
    }
  },
  created() {},
  methods: {
    changeChapter(item, index) {
      this.chapterList.forEach(i => {
        if (i.isSubShow !== this.chapterList[index].isSubShow) {
          i.isSubShow = false
        }
      })
      item.isSubShow = !item.isSubShow
    },
    secondsFormat(s) {
      var day = Math.floor(s / (24 * 3600))
      var hour = Math.floor((s - day * 24 * 3600) / 3600)
      var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
      var second = s - day * 24 * 3600 - hour * 3600 - minute * 60
      if (hour) {
        return hour + '时' + minute + '分' + second
      } else {
        if (second < 10) {
          if (minute < 10) {
            return '0' + minute + '分' + '0' + second + '秒'
          } else {
            return minute + '分' + '0' + second + '秒'
          }
        } else {
          return minute + '分' + second + '秒'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.catalog-box {
  padding-bottom: 87px;
  .catalog {
    .catalog-title {
      padding: 12px 21px;
      font-weight: 400;
      font-size: 14px;
      color: $robin-title-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .i-active {
        transform: rotate(180deg);
      }
    }
    .lesson-box {
      background: #f7f7f7;
      .no-lesson {
        padding: 9px 21px;
        font-size: 14px;
        color: $robin-title-color;
      }
      .lesson {
        display: flex;
        align-items: center;
        font-weight: 400;
        padding: 9px 21px;
        .lesson-title {
          font-size: 14px;
          color: $robin-title-color;
          margin-right: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 72px);
        }
        .lesson-time {
          color: #8e8e93;
          font-size: 12px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .catalog-box {
    .catalog {
      .catalog-title {
        padding: 12px 36px;
        cursor: pointer;
      }
      .lesson-box {
        padding-left: 36px;
        background: transparent;
        .no-lesson {
          padding: 8px 0 8px 32px;
          font-size: 14px;
          color: #8e8e93;
        }
        .lesson {
          padding: 8px 0 8px 32px;
          cursor: pointer;
          &:hover {
            background: #fdf1e2;
            border-radius: 5px;
          }
          &:hover .lesson-title,
          &:hover .lesson-time {
            color: $robin-essential-color;
          }
          .lesson-title {
            width: calc(100% - 240px);
            color: #8e8e93;
          }
        }
      }
    }
  }
}
</style>
