import request from '@/utils/request'
// 获取课程详细信息
export function getCourseDetails(id) {
    let query = {id : id}
    return request({
      url: '/train/course/detail',
      method: 'get',
      params: query,
      headers: {
        isToken: false // 不加token
      }
    })
  }